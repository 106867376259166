import { Patient, PatientListItem } from "./types";
import instance from "../../api/http_client";

export function getPatients(): Promise<PatientListItem[]> {
  return instance
    .get("patients")
    .then((res) => res.data.map(PatientListItem.fromJson));
}

export function getPatient(id: string): Promise<Patient> {
  return instance
    .get(`patients/${id}`)
    .then((res) => Patient.fromJson(res.data));
}

export function addPatient(
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string,
  allergies: string,
  dateOfBirth: string,
  weight: number,
  height: number,
  notes: string,
  icd10Codes: string,
  preferEmailCommunication: boolean,
  requireSignature: boolean,
  memberId: string,
  groupId: string
): Promise<Patient> {
  return instance
    .post("/patients/", {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      street_address: streetAddress,
      city: city,
      state: state,
      zip_code: zip,
      allergies: allergies,
      date_of_birth: dateOfBirth,
      weight: weight,
      height: height,
      notes: notes,
      icd_10_codes: icd10Codes,
      prefer_email_communication: preferEmailCommunication,
      require_delivery_signature: requireSignature,
      member_id: memberId,
      group_id: groupId,
    })
    .then((response) => Patient.fromJson(response.data));
}

export function updatePatient(
  patientId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string,
  allergies: string,
  dateOfBirth: string,
  weight: number,
  height: number,
  notes: string,
  icd10Codes: string,
  preferEmailCommunication: boolean,
  requireSignature: boolean,
  memberId: string,
  groupId: string
): Promise<Patient> {
  return instance
    .put(`/patients/${patientId}/`, {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      street_address: streetAddress,
      city: city,
      state: state,
      zip_code: zip,
      allergies: allergies,
      date_of_birth: dateOfBirth,
      weight: weight,
      height: height,
      notes: notes,
      icd_10_codes: icd10Codes,
      prefer_email_communication: preferEmailCommunication,
      require_delivery_signature: requireSignature,
      member_id: memberId,
      group_id: groupId,
    })
    .then((response) => Patient.fromJson(response.data));
}

export function deletePatient(patientId: number) {
  return instance.delete(`/patients/${patientId}`);
}
