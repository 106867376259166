import { FormProvider, useForm } from "react-hook-form";
import Card from "../../components/Card/Card";
import Input from "../Input/Input";
import styles from "./PatientForm.module.css";

import { useState } from "react";
import { addPatient, updatePatient } from "../../api/patients_network_api";
import { Patient } from "../../types/patient";
import PatientFinancialInfo from "./PatientFinancialInfo";
import PrimaryButton from "../Button2/PrimaryButton";

interface PatientFormProps {
  onDone: (patient: Patient | null) => void;
  editingPatient?: Patient;
}

interface IFormInput {
  firstName: string;
  lastName: string;
  allergies: string;
  streetAddress: string;
  state: string;
  city: string;
  zipCode: string;
  email: string;
  phone: string;
  dateOfBirth: string;
  weight: number;
  height: number;
  icd10Codes: string;
  notes: string;
  preferredCommunication: string;
  requireSignature: boolean;
  memberId: string;
  groupId: string;
}

const sanitizeDateOfBirth = (date: Date) => {
  return date.toISOString().substring(0, 10);
};

const getPrefersEmailFromBoolean = (value: string) => {
  return value === "email";
};

export default function PatientForm(props: PatientFormProps) {
  const [networkError, setNetworkError] = useState("");
  const [showFinancialForm, setShowFinancialForm] = useState(false);

  const editingPatient = props.editingPatient;
  const methods = useForm<IFormInput>({
    mode: "onSubmit",
    defaultValues: {
      firstName: editingPatient?.firstName,
      lastName: editingPatient?.lastName,
      allergies: editingPatient?.allergies,
      streetAddress: editingPatient?.streetAddress,
      state: editingPatient?.state,
      city: editingPatient?.city,
      zipCode: editingPatient?.zipCode,
      email: editingPatient?.email,
      phone: editingPatient?.phoneNumber,
      weight: editingPatient?.weight,
      height: editingPatient?.height,
      notes: editingPatient?.notes,
      icd10Codes: editingPatient?.icd10Codes,
      preferredCommunication:
        editingPatient?.preferEmailCommunication === false ? "phone" : "email",
      requireSignature: editingPatient?.requireDeliverySignature,
      groupId: editingPatient?.groupId,
      memberId: editingPatient?.memberId,
      dateOfBirth: props.editingPatient
        ? sanitizeDateOfBirth(props.editingPatient.dateOfBirth)
        : undefined,
    },
  });
  const errors = methods.formState.errors;

  const createOrUpdatePatient = (data: IFormInput, preferEmail: boolean) => {
    if (props.editingPatient) {
      return updatePatient(
        props.editingPatient.pk,
        data.firstName,
        data.lastName,
        data.email,
        data.phone,
        data.streetAddress,
        data.city,
        data.state,
        data.zipCode,
        data.allergies,
        data.dateOfBirth,
        data.weight,
        data.height,
        data.notes,
        data.icd10Codes,
        preferEmail,
        data.requireSignature,
        data.memberId,
        data.groupId
      );
    }
    return addPatient(
      data.firstName,
      data.lastName,
      data.email,
      data.phone,
      data.streetAddress,
      data.city,
      data.state,
      data.zipCode,
      data.allergies,
      data.dateOfBirth,
      data.weight,
      data.height,
      data.notes,
      data.icd10Codes,
      preferEmail,
      data.requireSignature,
      data.memberId,
      data.groupId
    );
  };

  const onSubmit = (data: IFormInput) => {
    const preferEmail = getPrefersEmailFromBoolean(data.preferredCommunication);
    return createOrUpdatePatient(data, preferEmail).catch((e) => {
      setNetworkError("Unable to save patient data");
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <Card className={styles.card}>
          {showFinancialForm ? (
            <PatientFinancialInfo
              onBack={() => setShowFinancialForm(false)}
            ></PatientFinancialInfo>
          ) : (
            <div className={styles.patientForm}>
              <h3>Enter patient information</h3>
              <div className={styles.formSection}>
                <div className={styles.inputGrid}>
                  <Input
                    id={"firstName"}
                    name={"firstName"}
                    label="First name"
                    autocomplete="off"
                    autoFocus={!props.editingPatient}
                    registerOptions={{ required: true }}
                    hasError={!!errors.firstName}
                  ></Input>
                  <Input
                    id={"lastName"}
                    name={"lastName"}
                    label="Last name"
                    registerOptions={{ required: true }}
                    hasError={!!errors.lastName}
                  ></Input>
                  <Input
                    className={styles.fullSpanInput}
                    id={"email"}
                    name={"email"}
                    label="Email"
                    registerOptions={{ required: true }}
                    hasError={!!errors.email}
                  ></Input>
                  <Input
                    className={styles.fullSpanInput}
                    id={"phone"}
                    name={"phone"}
                    label="Phone"
                    registerOptions={{ required: true }}
                    hasError={!!errors.phone}
                  ></Input>
                  <Input
                    className={styles.fullSpanInput}
                    id={"streetAddress"}
                    name={"streetAddress"}
                    label="Street address"
                    registerOptions={{ required: true }}
                    hasError={!!errors.streetAddress}
                  ></Input>
                  <Input
                    className={styles.fullSpanInput}
                    id={"city"}
                    name={"city"}
                    label="City"
                    registerOptions={{ required: true }}
                    hasError={!!errors.city}
                  ></Input>
                  <Input
                    id={"state"}
                    name={"state"}
                    label="State"
                    registerOptions={{ required: true }}
                    hasError={!!errors.state}
                  ></Input>
                  <Input
                    id={"zip"}
                    name={"zipCode"}
                    label="Zip code"
                    registerOptions={{ required: true }}
                    hasError={!!errors.zipCode}
                  ></Input>
                  <Input
                    className={styles.fullSpanInput}
                    id={"dateOfBirth"}
                    name={"dateOfBirth"}
                    type="date"
                    label="Date of birth"
                    max={sanitizeDateOfBirth(new Date())}
                    min={sanitizeDateOfBirth(new Date(Date.UTC(1900, 0, 1)))}
                    registerOptions={{
                      required: true,
                    }}
                    hasError={!!errors.dateOfBirth}
                  ></Input>
                  <Input
                    id={"memberId"}
                    name={"memberId"}
                    label="Member ID"
                  ></Input>
                  <Input
                    id={"groupId"}
                    name={"groupId"}
                    label="Group ID"
                  ></Input>
                  {networkError && (
                    <p className={styles.networkError}>{networkError}</p>
                  )}
                </div>
                <div className={styles.inputGrid}>
                  <Input
                    registerOptions={{ valueAsNumber: true }}
                    id={"weight"}
                    name={"weight"}
                    label="Weight (lbs)"
                    type="number"
                    min="0"
                  ></Input>
                  <Input
                    registerOptions={{ valueAsNumber: true }}
                    id={"height"}
                    name={"height"}
                    label="Height (in)"
                    type="number"
                    min="0"
                  ></Input>
                  <Input
                    className={styles.fullSpanInput}
                    id={"allergies"}
                    name={"allergies"}
                    label="Allergies (optional)"
                  ></Input>
                  <Input
                    className={styles.fullSpanInput}
                    id={"icd10Codes"}
                    name={"icd10Codes"}
                    label="ICD-10 codes (optional)"
                  ></Input>
                  <Input
                    className={styles.fullSpanInput}
                    id={"notes"}
                    name={"notes"}
                    label="Notes (optional)"
                  ></Input>
                  <p className={styles.communicationLabel}>
                    Preferred communication:
                  </p>
                  <div className={styles.communication}>
                    <div>
                      <input
                        {...methods.register("preferredCommunication")}
                        type="radio"
                        id="emailCommunication"
                        value={"email"}
                      />
                      <label htmlFor="emailCommunication">Email</label>
                    </div>
                    <div>
                      <input
                        {...methods.register("preferredCommunication")}
                        type="radio"
                        id="phoneCommunication"
                        value={"phone"}
                      />
                      <label htmlFor="phoneCommunication">Phone</label>
                    </div>
                  </div>
                  <div className={styles.fullSpanInput}>
                    <input
                      {...methods.register("requireSignature")}
                      className={styles.signatureCheckbox}
                      type="checkbox"
                      id="requireSignature"
                    ></input>
                    <label
                      className={styles.signatureLabel}
                      htmlFor="requireSignature"
                    >
                      Require signature upon delivery
                    </label>
                  </div>
                </div>
              </div>

              <div className={styles.buttonWrap}>
                <button
                  type="button"
                  className={styles.cancelButton}
                  onClick={() => props.onDone(null)}
                >
                  Cancel
                </button>
                <PrimaryButton
                // onClick={() =>
                //   methods.trigger().then((validated) => {
                //     if (validated) {
                //       setShowFinancialForm(true);
                //     }
                //   })
                // }
                >
                  Submit
                </PrimaryButton>
              </div>
            </div>
          )}
        </Card>
      </form>
    </FormProvider>
  );
}
