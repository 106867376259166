import styles from "./DashboardPageBody.module.css";
import Back from "../../assets/Back.svg";
import { Link } from "react-router-dom";

interface DashboardPageBodyProps {
  children: React.ReactNode;
  title: string;
  headerSuffix?: React.ReactNode;
  backLink?: string;
  description?: string;
}

export default function DashboardPageBody(props: DashboardPageBodyProps) {
  return (
    <div className={styles.dashboardPageBody}>
      <div className={styles.headerWrap}>
        <div className={styles.headerBox}>
          <div className={styles.headerPrefix}>
            {props.backLink && (
              <Link to={props.backLink} className={styles.backLink}>
                <img src={Back} alt="Copy"></img>
              </Link>
            )}
            <h2 className={styles.title}>{props.title}</h2>
          </div>
          {props.headerSuffix}
        </div>
        {props.description && (
          <p className={styles.description}>{props.description}</p>
        )}
      </div>
      {props.children}
    </div>
  );
}
