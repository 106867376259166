import { useState } from "react";
import styles from "./Faq.module.css";
import ChevronDown from "../../assets/ChevronDown.svg";

export default function FaqItem(props: {
  title: string;
  children: React.ReactNode;
  initiallyExpanded: boolean;
}) {
  const [expanded, setExpanded] = useState(props.initiallyExpanded);

  return (
    <button className={styles.faqItem} onClick={() => setExpanded(!expanded)}>
      <div className={styles.header}>
        <h2>{props.title}</h2>
        <img
          src={ChevronDown}
          alt="Expand"
          className={`${styles.chevron} ${expanded ? styles.flip : ""}`}
        ></img>
      </div>
      {expanded && <div className={styles.answer}>{props.children}</div>}
    </button>
  );
}
