import { useEffect, useState } from "react";
import { PatientListItem } from "./types";
import { ApiStatus } from "../../types/api_status";
import { getPatients } from "./api";
import styles from "./styles.module.css";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import Search from "../../components/Search/Search";
import { useNavigate } from "react-router";
import PrimaryButton from "../../components/Button2/PrimaryButton";
import PatientDetailCard from "./components/PatientDetailCard";
import DashboardPageEmptyState from "../../components/DashboardPageBody/DashboardStates/DashboardPageEmptyState";
import DashboardPageFailState from "../../components/DashboardPageBody/DashboardStates/DashboardPageFailState";
import DashboardPageLoadState from "../../components/DashboardPageBody/DashboardStates/DashboardPageLoadState";

interface PatientsFetchState {
  patients: PatientListItem[] | null;
  status: ApiStatus;
}

const _filterPatients = (filter: string, patients: PatientListItem[]) => {
  return patients.filter(
    (p) =>
      p.fullName.toUpperCase().includes(filter.toUpperCase()) ||
      (p.memberId?.toUpperCase().includes(filter.toUpperCase()) ?? false)
  );
};

export default function Patients() {
  const navigate = useNavigate();

  const [patientsFilter, setPatientsFilter] = useState("");

  const [patientsFetchState, setPatientsFetchState] =
    useState<PatientsFetchState>({
      patients: null,
      status: ApiStatus.LOADING,
    });

  useEffect(() => {
    getPatients()
      .then((patients) =>
        setPatientsFetchState(() => ({
          patients: patients,
          status: ApiStatus.SUCCESS,
        }))
      )
      .catch(() => {
        setPatientsFetchState((prev) => ({
          ...prev,
          status: ApiStatus.FAILURE,
        }));
      });
  }, []);

  return (
    <DashboardPageBody
      title={"Your patients"}
      headerSuffix={
        <div className={styles.suffix}>
          <Search
            placeholder="Search patients"
            onChange={(val) => setPatientsFilter(val)}
          ></Search>
          <PrimaryButton
            onClick={() => {
              navigate("/patient/create");
            }}
          >
            <img
              alt="Plus"
              className={styles.plusIcon}
              src={require(`../../assets/PlusWhite.svg`).default}
            ></img>
            Add patient
          </PrimaryButton>
        </div>
      }
    >
      {patientsFetchState.patients ? (
        patientsFetchState.patients.length === 0 ? (
          <DashboardPageEmptyState
            title="You don't have any patients yet"
            subtitle="Get started by clicking add patient."
          ></DashboardPageEmptyState>
        ) : _filterPatients(patientsFilter, patientsFetchState.patients)
            .length === 0 ? (
          <DashboardPageEmptyState
            title={`No results for "${patientsFilter}"`}
          ></DashboardPageEmptyState>
        ) : (
          _filterPatients(patientsFilter, patientsFetchState.patients).map(
            (p) => (
              <PatientDetailCard
                patient={p}
                linkToPatientDetail={true}
              ></PatientDetailCard>
            )
          )
        )
      ) : patientsFetchState.status === ApiStatus.LOADING ? (
        <DashboardPageLoadState></DashboardPageLoadState>
      ) : (
        <DashboardPageFailState
          errorMessage={"Unable to load patients."}
          subtitle="Please try reloading."
        ></DashboardPageFailState>
      )}
    </DashboardPageBody>
  );
}
