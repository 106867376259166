import styles from "./AddToCart.module.css";

interface AddToCartButtonProps {
  onClick?: () => void;
  primary?: boolean;
  width?: string;
  padding?: string;
  submit?: boolean;
  disabled?: boolean;
}

export default function AddToCartButton(props: AddToCartButtonProps) {
  return (
    <button
      disabled={props.disabled}
      type={props.submit ? "submit" : undefined}
      style={{
        width: props.width,
        padding: props.padding,
      }}
      className={`${styles.default} ${props.primary ? styles.primary : ""}`}
      onClick={props.onClick}
    >
      <img
        className={styles.addToCartIcon}
        src={
          require(props.primary
            ? `../../assets/PlusWhite.svg`
            : `../../assets/Plus.svg`).default
        }
        alt="Cart"
      ></img>
      <p>Submit order</p>
    </button>
  );
}
