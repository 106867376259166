interface ConsentFormAccessTokenJson {
  id: number;
  patient_id: number;
  patient_name: string;
  token: string;
  expires_at: Date;
}

export class ConsentFormAccessToken {
  constructor(
    public id: number,
    public patientId: number,
    public patientName: string,
    public tokenValue: string,
    public expiresAt: Date
  ) {}

  static fromJson(data: ConsentFormAccessTokenJson): ConsentFormAccessToken {
    return new ConsentFormAccessToken(
      data.id,
      data.patient_id,
      data.patient_name,
      data.token,
      new Date(data.expires_at)
    );
  }
}
