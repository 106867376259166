import { useEffect, useState } from "react";
import DashboardPageBody from "../../../components/DashboardPageBody/DashboardPageBody";
import { ApiStatus } from "../../../types/api_status";
import { useNavigate, useParams } from "react-router";
import styles from "./styles.module.css";
import { getPrettyDate, isInternalPath } from "../../../utils";
import LinkIcon from "../../../assets/Link.svg";
import Trash from "../../../assets/Trash.svg";
import Edit from "../../../assets/Edit.svg";
import ConsentFormLinkPopup from "../../../components/ConsentFormLinkPopup/ConsentFormLinkPopup";

import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import PatientDetailCard from "../components/PatientDetailCard";
import { deletePatient, getPatient } from "../api";
import { Patient } from "../types";
import { Link, useSearchParams } from "react-router-dom";
import PrimaryButton from "../../../components/Button2/PrimaryButton";
import SecondaryButton from "../../../components/Button2/SecondaryButton";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { toast } from "react-toastify";

interface PatientDetailFetchState {
  status: ApiStatus;
  patient: Patient | null;
}

export default function PatientDetail() {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const [searchParams] = useSearchParams();
  let backPath = searchParams.get("back");
  if (backPath && !isInternalPath(backPath)) {
    backPath = null;
  }

  const [patientDetailFetchState, setPatientDetailFetchState] =
    useState<PatientDetailFetchState>({
      status: ApiStatus.LOADING,
      patient: null,
    });

  const [deletePatientState, setDeletePatientState] = useState<ApiStatus>(
    ApiStatus.INITIAL
  );

  const _deletePatient = () => {
    if (!patient) {
      return;
    }
    setDeletePatientState(ApiStatus.LOADING);
    deletePatient(patient.id)
      .then(() => {
        setDeletePatientState(ApiStatus.SUCCESS);
        toast.success("Successfully deleted patient");
        navigate("/patients");
      })
      .catch((e) => {
        setDeletePatientState(ApiStatus.FAILURE);
        hideGlobalOverlay();
        if (e.response.data) {
          toast.error(e.response.data[0]);
        } else {
          toast.error("Error deleting patient");
        }
      });
  };

  useEffect(() => {
    if (patientId === undefined) {
      setPatientDetailFetchState((prev) => ({
        ...prev,
        status: ApiStatus.FAILURE,
      }));
    } else {
      getPatient(patientId)
        .then((result) => {
          setPatientDetailFetchState((prev) => ({
            ...prev,
            patient: result,
          }));
        })
        .catch((e) => {
          setPatientDetailFetchState((prev) => ({
            ...prev,
            status: ApiStatus.FAILURE,
          }));
        });
    }
  }, [patientId]);

  const patient = patientDetailFetchState.patient;

  return (
    <DashboardPageBody
      backLink={backPath ?? "/patients"}
      title="Patient"
      headerSuffix={
        patientDetailFetchState.patient && (
          <div className={styles.actions}>
            <SecondaryButton
              onClick={() => {
                if (!patientId) {
                  return;
                }
                setGlobalOverlay(
                  <ConsentFormLinkPopup
                    patientId={patientId}
                    onDone={hideGlobalOverlay}
                  ></ConsentFormLinkPopup>
                );
              }}
            >
              <img
                src={LinkIcon}
                title="Get patient form link"
                alt="Patient link"
                className={styles.linkIcon}
              ></img>
              Consent form link
            </SecondaryButton>
            <SecondaryButton
              onClick={() => {
                if (!patient) {
                  return;
                }
                navigate(`/patient/${patient.id}/edit`);
              }}
            >
              <img src={Edit} alt="Trash" className={styles.linkIcon} />
              Edit
            </SecondaryButton>
            <SecondaryButton
              onClick={() => {
                setGlobalOverlay(
                  <ConfirmationDialog
                    title={"Are you sure you want to delete this patient?"}
                    isDestructive={true}
                    rightLabel="Delete"
                    loading={deletePatientState === ApiStatus.LOADING}
                    onConfirm={_deletePatient}
                    onCancel={hideGlobalOverlay}
                  ></ConfirmationDialog>
                );
              }}
            >
              <img src={Trash} alt="Trash" className={styles.linkIcon} />
              Delete
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                navigate(`/patients/${patientId}/prescriptions/create`);
              }}
            >
              <img
                alt="Plus"
                className={styles.plusIcon}
                src={require(`../../../assets/PlusWhite.svg`).default}
              ></img>
              Add prescription
            </PrimaryButton>
          </div>
        )
      }
    >
      <div>
        <PatientDetailCard
          patient={patientDetailFetchState.patient}
        ></PatientDetailCard>
        {patient && (
          <div className={styles.prescriptionsSection}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Prescription</th>
                  <th>Quantity</th>
                  <th>Received</th>
                  <th>Consent forms</th>
                  <th>Created on</th>
                </tr>
              </thead>
              <tbody>
                {patient.prescriptions.length === 0 ? (
                  <tr>
                    <td colSpan={6} className={styles.emptyState}>
                      No prescriptions yet
                    </td>
                  </tr>
                ) : (
                  patient.prescriptions.map((prescription) => (
                    <tr key={prescription.id}>
                      <td>
                        <Link
                          to={`/patients/${patient.id}/prescriptions/${prescription.id}`}
                          className={styles.prescriptionLink}
                        >
                          {prescription.medicationName}{" "}
                          {prescription.medicationStrength}
                        </Link>
                      </td>
                      <td>
                        {prescription.medicationQuantity}{" "}
                        {prescription.medicationFormulation}
                      </td>
                      <td>{prescription.received ? "Yes" : "No"}</td>
                      <td>
                        {prescription.hasUnsignedForms
                          ? "Incomplete"
                          : "Completed"}
                      </td>
                      <td>{getPrettyDate(prescription.createdAt)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </DashboardPageBody>
  );
}
