import { BrowserRouter as Router } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import AuthUserProvider from "./providers/AuthUserProvider";
import Sidebar from "./components/Sidebar/Sidebar";
import styles from "./App.module.css";
import MyRoutes from "./components/MyRoutes/MyRoutes";
import GlobalOverlayProvider from "./providers/GlobalOverlayProvider";
import MyToastContainer from "./components/Toast/MyToastContainer";
import { Auth0Provider } from "@auth0/auth0-react";

function App() {
  useEffect(() => {
    document.title = "MediCircle";
  }, []);

  return isMobile ? (
    <div className={styles.mobile}>Please view on a computer.</div>
  ) : (
    <Router>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ""}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://medicircle/api",
        }}
      >
        <AuthUserProvider>
          <GlobalOverlayProvider>
            <div className={styles.app}>
              <Sidebar />
              <div className={styles.content}>
                <MyRoutes />
              </div>
            </div>
          </GlobalOverlayProvider>
        </AuthUserProvider>
        <MyToastContainer />
      </Auth0Provider>
    </Router>
  );
}

export default App;
