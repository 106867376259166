export const REQUIRED_FIELD_ERROR_MESSAGE = "This field is required";

export const BASE_API_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000/v1"
    : "https://api.medicirclerx.com/v1";

export const DASHBOARD_URL = "/";
export const LOGIN_URL = "/login";
export const SIGNUP_URL = "/register";

export const CONSENT_ACCESS_CODE_URL =
  "https://patient.medicirclerx.com/welcome";

export const HANDLING_FEE_EXPLAINER = `MediCircle operates in accordance with Texas Health and Safety Code
  Chapter 442. As stated in the legislation, the Handling Fee covers
  “the costs of inspecting, storing, labeling, and dispensing” the
  prescription drug. The Handling Fee is the only Fee that may be passed
  on to the recipient patient.`;

export const ADMIN_FEE_EXPLAINER = `The Admin Fee covers the technological, logistical, and administrative
  costs associated with MediCircle’s operations to ensure the program
  can continue to provide affordable medications to patients.`;

export const SOURCING_FEE_EXPLAINER = `MediCircle provides patients with access to affordable medication by
  reclaiming and redistributing in-demand, hard-to-obtain medications.
  To accomplish our mission and maximize impact, MediCircle prioritizes
  the acquisition of medications that offer the highest value to
  patients and the health system. These medications often require
  additional resources, time, and effort to collect. The Sourcing Fee
  covers the extra resources MediCircle allocates to collect particular
  medications, referred to as ‘target’ medications.`;

export const STATE_CODES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
