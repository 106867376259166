import { Dispense, DispenseJson } from "./dispense";

export interface PrescriptionJson {
  id: number;
  label: string;
  consent_form_signed: boolean;
  total_refills: number;
  remaining_refills: number;
  due_date: string;
  received: boolean;
  quantity: number;
  archived: boolean;
  patient: string;
}

export class Prescription {
  constructor(
    public pk: number,
    public label: string,
    public consentFormSigned: boolean,
    public totalRefills: number,
    public remainingRefills: number,
    public dueDate: Date,
    public received: boolean,
    public quantity: number,
    public archived: boolean,
    public patientName: string
  ) {}

  static fromJson(data: PrescriptionJson): Prescription {
    return new Prescription(
      data.id,
      data.label,
      data.consent_form_signed,
      data.total_refills,
      data.remaining_refills,
      new Date(data.due_date),
      data.received,
      data.quantity,
      data.archived,
      data.patient
    );
  }
}

export interface PrescriptionJson2 {
  id: number;
  medication_name: string;
  medication_strength: string;
  medication_formulation: string;
  quantity: number;
  patient_id: number;
  created_at: string;
  received: boolean;
  dispenses: DispenseJson[];
}

export class Prescription2 {
  constructor(
    public id: number,
    public medicationName: string,
    public medicationStrength: string,
    public medicationFormulation: string,
    public quantity: number,
    public patientId: number,
    public createdAt: Date,
    public received: boolean,
    public dispenses: Dispense[]
  ) {}

  static fromJson(data: PrescriptionJson2): Prescription2 {
    return new Prescription2(
      data.id,
      data.medication_name,
      data.medication_strength,
      data.medication_formulation,
      data.quantity,
      data.patient_id,
      new Date(data.created_at),
      data.received,
      data.dispenses.map(Dispense.fromJson)
    );
  }
}

export interface PrescriptionListItemJson {
  id: string;
  medication_name: string;
  medication_strength: string;
  medication_formulation: string;
  patient_name: string;
  quantity: number;
  received: boolean;
  has_unsigned_forms: boolean;
  created_at: string;
}

export class PrescriptionListItem {
  constructor(
    public id: string,
    public medicationName: string,
    public medicationStrength: string,
    public medicationQuantity: number,
    public medicationFormulation: string,
    public patientName: string,
    public received: boolean,
    public hasUnsignedForms: boolean,
    public createdAt: Date
  ) {}

  static fromJson(data: PrescriptionListItemJson): PrescriptionListItem {
    return new PrescriptionListItem(
      data.id,
      data.medication_name,
      data.medication_strength,
      data.quantity,
      data.medication_formulation,
      data.patient_name,
      data.received,
      data.has_unsigned_forms,
      new Date(data.created_at)
    );
  }
}
