import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import { Order } from "../../../types/order";
import OrderHistoryConsentLink from "../OrderHistoryConsentLink/OrderHistoryConsentLink";
import styles from "./OrderHistoryList.module.css";
import WarningIcon from "../../../assets/Warning.svg";

interface ActionRequiredBoxProps {
  order: Order;
}

export default function ActionRequiredBox(props: ActionRequiredBoxProps) {
  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const missingConsents = props.order.orderItems.filter(
    (o) => !o.consentSigned
  );

  const missingPrescriptions = props.order.orderItems.filter(
    (o) => !o.prescriptionReceived
  );

  const buildMissingPrescriptionsString = () => {
    return missingPrescriptions.reduce(
      (a, b, index) =>
        `${a + b.medication.displayName} ${b.medication.strength}${
          index === missingPrescriptions.length - 1
            ? ""
            : index === missingPrescriptions.length - 2
            ? " and "
            : ", "
        }`,
      ""
    );
  };

  return (
    <div className={styles.actionRequired}>
      <img
        className={styles.actionRequiredIcon}
        src={WarningIcon}
        alt={""}
      ></img>
      <div>
        <p className={styles.actionRequiredDescription}>
          The following action(s) must be taken before we can ship this order:
        </p>
        <ul>
          {missingConsents.length > 0 && (
            <li>
              {props.order.patient.fullName} must sign the consent form(s) for
              this order.{" "}
              <span>
                <button
                  onClick={() =>
                    setGlobalOverlay(
                      <OrderHistoryConsentLink
                        onDone={hideGlobalOverlay}
                        patientName={props.order.patient.fullName}
                        token="foo"
                      ></OrderHistoryConsentLink>
                    )
                  }
                  className={styles.sendLink}
                >
                  Get template email
                </button>
              </span>
            </li>
          )}
          {missingPrescriptions.length > 0 && (
            <li>
              We need {props.order.patient.fullName}'s prescription for{" "}
              <span className={styles.requiredPrescription}>
                {buildMissingPrescriptionsString()}
              </span>
              . You can send it to us over fax at (832) 202-1304, call us at
              (832) 380-4400, or send it to MediCircle on Surescripts.
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
