import styles from "./PatientDetailCard.module.css";
import { getPrettyDate } from "../../../utils";
import Card from "../../../components/Card/Card";
import { Patient, PatientListItem } from "../types";
import { Link } from "react-router-dom";
import View from "../../../assets/View.svg";

interface PatientDetailCardProps {
  patient: Patient | PatientListItem | null;
  errorMessage?: string;
  linkToPatientDetail?: boolean;
}

export default function PatientDetailCard(props: PatientDetailCardProps) {
  return (
    <Card className={styles.patientListItem}>
      {props.patient ? (
        <div className={styles.mainContent}>
          <div className={styles.start}>
            <img
              className={styles.patientIcon}
              src={require("../../../assets/PersonGrey.svg").default}
              alt="Patient icon"
            ></img>
            <div className={styles.infoBox}>
              {props.linkToPatientDetail ? (
                <Link
                  to={`/patients/${props.patient.id}`}
                  className={`${styles.name} ${styles.link}`}
                >
                  {props.patient.fullName}
                </Link>
              ) : (
                <p className={styles.name}>{props.patient.fullName}</p>
              )}
              <p>{props.patient.displayAddress}</p>
              <div className={styles.contactBox}>
                <p>{props.patient.email}</p>
                <span className={styles.contactDivider}>●</span>
                <p>{props.patient.phoneNumber}</p>
                <span className={styles.contactDivider}>●</span>
                <p>DOB {getPrettyDate(props.patient.dateOfBirth)}</p>
                {props.patient.memberId && (
                  <>
                    <span className={styles.contactDivider}>●</span>
                    <p>Member ID: {props.patient.memberId}</p>
                  </>
                )}
                {props.patient.groupId && (
                  <>
                    <span className={styles.contactDivider}>●</span>
                    <p>Group ID: {props.patient.groupId}</p>
                  </>
                )}
              </div>
            </div>
          </div>
          {props.linkToPatientDetail && (
            <div className={styles.actions}>
              <Link
                to={`/patients/${props.patient.id}`}
                className={styles.actionButton}
              >
                <img src={View} alt="Get link"></img>
              </Link>
            </div>
          )}
        </div>
      ) : props.errorMessage ? (
        <></>
      ) : (
        <></>
      )}
    </Card>
  );
}
