import { useEffect, useState } from "react";
import { ApiStatus } from "../../../types/api_status";
import { Medication } from "../../../types/medication";
import { getInventory } from "../../../api/inventory_network_api";

import DashboardPageBody from "../../../components/DashboardPageBody/DashboardPageBody";
import { useForm } from "react-hook-form";
import {
  precheckPrescription,
  submitPrescription,
} from "../../Prescriptions/api";
import styles from "./styles.module.css";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { getPatient } from "../api";
import { Patient } from "../types";
import PrimaryButton from "../../../components/Button2/PrimaryButton";
import { isInternalPath, round } from "../../../utils";

interface MedicationsFetchState {
  status: ApiStatus;
  medications: Medication[] | null;
}

interface PatientFetchState {
  status: ApiStatus;
  patient: Patient | null;
}

interface FormInput {
  medicationId: string;
  daysSupply: number;
  quantity: number;
  dueDate: string;
  previouslyFilled: string;
  remainingRefills: number;
}

function getLocalizedTodayString() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function numberWithCommas(x: string) {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export default function PrescriptionForm() {
  const { patientId } = useParams();

  const [searchParams] = useSearchParams();
  const medicationId = searchParams.get("medicationId");

  let backPath = searchParams.get("back");
  if (backPath && !isInternalPath(backPath)) {
    backPath = null;
  }

  const navigate = useNavigate();
  const notifySuccess = (text: string) => toast.success(text);

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<FormInput>();

  const [medicationsFetchState, setMedicationsFetchState] =
    useState<MedicationsFetchState>({
      status: ApiStatus.LOADING,
      medications: null,
    });

  const [patientFetchState, setPatientFetchState] = useState<PatientFetchState>(
    {
      status: ApiStatus.LOADING,
      patient: null,
    }
  );

  const _submitPrescription = (data: FormInput) => {
    if (!patientId) {
      alert("Error submitting prescription");
      return;
    }

    return submitPrescription(
      data.medicationId,
      patientId,
      data.daysSupply,
      data.quantity,
      data.dueDate,
      data.previouslyFilled,
      data.remainingRefills
    )
      .then((prescription) => {
        notifySuccess("Successfully created prescription");
        navigate(`/patients/${patientId}/prescriptions/${prescription.id}`);
      })
      .catch((error) => {
        alert("Error submitting prescription");
      });
  };

  const _promptConfirmationDialog = (medicationId: number) => {
    const med = medicationsFetchState.medications?.find(
      (m) => m.pk === medicationId
    );

    if (!med) {
      alert("Error submitting prescription");
      return;
    }

    const multiplier = getValues("quantity") / med.avgQtyPerScript;
    const scaledSourcingFee = med.pricingTier.sourcingFee * multiplier;

    const totalPrice =
      med.pricingTier.handlingFee +
      med.pricingTier.adminFee +
      scaledSourcingFee;

    const totalPriceStr = numberWithCommas(round(totalPrice, 2).toFixed(2));

    return window.confirm(
      `There will be $${totalPriceStr} in fees per dispense. Confirm order?`
    );
  };

  const onSubmit = (data: FormInput) => {
    if (patientId === undefined) {
      alert("Error submitting prescription");
      return;
    }

    const confirmed = _promptConfirmationDialog(+data.medicationId);

    if (!confirmed) {
      return;
    }

    return precheckPrescription(
      data.medicationId,
      patientId,
      data.daysSupply,
      data.quantity,
      data.dueDate,
      data.previouslyFilled,
      data.remainingRefills
    )
      .then((result) => {
        if (result.canFill) {
          return _submitPrescription(data);
        } else if (result.numFillsAvailable > 0) {
          const confirmed = window.confirm(
            `We only have enough inventory to fulfill ${result.numFillsAvailable} dispenses of this prescription. Proceed?`
          );
          if (confirmed) {
            return _submitPrescription(data);
          }
        } else {
          alert("Not enough inventory to fill this prescription.");
        }
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          alert(e.response.data.error);
        } else {
          alert("Error performing prescription precheck");
        }
      });
  };

  useEffect(() => {
    if (
      medicationsFetchState.medications &&
      medicationsFetchState.medications.length > 0 &&
      medicationId
    ) {
      setValue("medicationId", medicationId);
    }
  }, [medicationsFetchState, medicationId, setValue]);

  useEffect(() => {
    const _getPatients = () => {
      if (patientId) {
        getPatient(patientId)
          .then((patient) =>
            setPatientFetchState(() => ({
              patient: patient,
              status: ApiStatus.SUCCESS,
            }))
          )
          .catch(() => {
            setPatientFetchState((prev) => ({
              ...prev,
              status: ApiStatus.FAILURE,
            }));
          });
      }
    };
    const _getMedications = () => {
      getInventory()
        .then((meds) => {
          setMedicationsFetchState(() => ({
            medications: meds,
            status: ApiStatus.SUCCESS,
          }));
        })
        .catch(() => {
          setMedicationsFetchState((prev) => ({
            ...prev,
            status: ApiStatus.FAILURE,
          }));
        });
    };
    _getPatients();
    _getMedications();
  }, [patientId, medicationId, setValue]);

  return (
    <DashboardPageBody
      title="Add prescription"
      backLink={backPath ?? `/patients/${patientId}`}
      description={`You are currently adding a prescription for ${
        patientFetchState?.patient?.fullName ?? ""
      }.`}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.pageBody}>
          <div className={styles.pageBodyHeading}>PRESCRIPTION DETAILS</div>
          <div className={styles.inputs}>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="medicationId">
                Medication
              </label>
              <select
                {...register("medicationId", { required: true })}
                className={styles.select}
                id="medicationId"
              >
                <option value="">Select a medication</option>
                {medicationsFetchState.medications &&
                  medicationsFetchState.medications.map((m) => (
                    <option value={m.pk} key={m.pk}>
                      {m.displayName} {m.strength}
                    </option>
                  ))}
              </select>
              {errors.medicationId && (
                <p className={styles.error}>Medication is required</p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="dueDate">
                Due Date
              </label>
              <input
                type="date"
                min={getLocalizedTodayString()}
                {...register("dueDate", { required: true })}
                className={styles.select}
                id="dueDate"
              />
              {errors.dueDate && (
                <p className={styles.error}>Due date is required</p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="daysSupply">
                Days Supply
              </label>
              <input
                type="number"
                className={styles.input}
                id="daysSupply"
                {...register("daysSupply", { required: true })}
              />
              {errors.daysSupply && (
                <p className={styles.error}>Days Supply is required</p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="quantity">
                Quantity
              </label>
              <input
                className={styles.input}
                type="number"
                id="quantity"
                {...register("quantity", { required: true })}
              />
              {errors.quantity && (
                <p className={styles.error}>Quantity is required</p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label} htmlFor="remainingRefills">
                Remaining refills
              </label>
              <input
                className={styles.input}
                type="number"
                id="remainingRefills"
                {...register("remainingRefills", { required: true })}
              />
              {errors.remainingRefills && (
                <p className={styles.error}>Remaining Refills is required</p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <input
                type="checkbox"
                {...register("previouslyFilled")}
                id="previouslyFilled"
              />
              <label
                className={`${styles.label} ${styles.checkBoxLabel}`}
                htmlFor="previouslyFilled"
              >
                This prescription was previously filled at another pharmacy
              </label>
              {errors.previouslyFilled && (
                <p className={styles.error}>Required</p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.submitWrap}>
          <Link
            className={styles.cancelButton}
            to={backPath ?? `/patients/${patientId}`}
          >
            Cancel
          </Link>
          <PrimaryButton type="submit" loading={isSubmitting}>
            Submit order
          </PrimaryButton>
        </div>
      </form>
    </DashboardPageBody>
  );
}
