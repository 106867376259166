import { Navigate, Route, Routes } from "react-router";
import Cart from "../../pages/Cart/Cart";
import Dashboard from "../../pages/Inventory/Inventory";
import OrderHistory from "../../pages/OrderHistory/OrderHistory";
import Patients from "../../pages/Patients2";
import ProtectedRoute from "./ProtectedRoute";
import Inventory from "../../pages/Inventory/Inventory";
import Faq from "../../pages/Faq/Faq";
import ContactSupport from "../../pages/ContactSupport/ContactSupport";
import TaskList from "../../pages/TaskList/TaskList";
import PrescriptionDetail from "../../pages/Prescriptions/PrescriptionDetail";
import AddPrescription from "../../pages/Patients2/PrescriptionForm";
import PatientForm from "../../pages/Patients2/PatientForm";
import PatientDetail from "../../pages/Patients2/PatientDetail";
import DispenseHistory from "../../pages/DispenseHistory";
import Landing from "../../pages/Landing";

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Landing></Landing>}></Route>
      <Route path="/inventory" element={<ProtectedRoute />}>
        <Route path="/inventory" element={<Dashboard></Dashboard>}></Route>
      </Route>
      <Route path="/viewOrder" element={<ProtectedRoute />}>
        <Route path="/viewOrder" element={<Cart></Cart>}></Route>
      </Route>
      <Route path="/orders" element={<ProtectedRoute />}>
        <Route path="/orders" element={<OrderHistory></OrderHistory>}></Route>
      </Route>
      {/* <Route path="/prescriptions" element={<ProtectedRoute />}>
        <Route
          path="/prescriptions"
          element={<Prescriptions></Prescriptions>}
        ></Route>
      </Route> */}
      <Route
        path="/patients/:patientId/prescriptions/:prescriptionId"
        element={<ProtectedRoute />}
      >
        <Route
          path="/patients/:patientId/prescriptions/:prescriptionId"
          element={<PrescriptionDetail></PrescriptionDetail>}
        ></Route>
      </Route>
      <Route
        path="/patients/:patientId/prescriptions/create"
        element={<ProtectedRoute />}
      >
        <Route
          path="/patients/:patientId/prescriptions/create"
          element={<AddPrescription></AddPrescription>}
        ></Route>
      </Route>
      <Route path="/dispenses" element={<ProtectedRoute />}>
        <Route
          path="/dispenses"
          element={<DispenseHistory></DispenseHistory>}
        ></Route>
      </Route>
      <Route path="/patients" element={<ProtectedRoute />}>
        <Route path="/patients" element={<Patients></Patients>}></Route>
      </Route>
      <Route path="/patients/:patientId" element={<ProtectedRoute />}>
        <Route
          path="/patients/:patientId"
          element={<PatientDetail></PatientDetail>}
        ></Route>
      </Route>
      <Route path="/patient/create" element={<ProtectedRoute />}>
        <Route
          path="/patient/create"
          element={<PatientForm></PatientForm>}
        ></Route>
      </Route>
      <Route path="/patient/:patientId/edit" element={<ProtectedRoute />}>
        <Route
          path="/patient/:patientId/edit"
          element={<PatientForm></PatientForm>}
        ></Route>
      </Route>
      <Route path="/faq" element={<ProtectedRoute />}>
        <Route path="/faq" element={<Faq></Faq>}></Route>
      </Route>
      <Route path="/support" element={<ProtectedRoute />}>
        <Route
          path="/support"
          element={<ContactSupport></ContactSupport>}
        ></Route>
      </Route>{" "}
      <Route path="/tasks" element={<ProtectedRoute />}>
        <Route path="/tasks" element={<TaskList></TaskList>}></Route>
      </Route>
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<Inventory></Inventory>}></Route>
      </Route>
      <Route path="*" element={<ProtectedRoute />}>
        <Route path="*" element={<Navigate to="/inventory"></Navigate>}></Route>
      </Route>
    </Routes>
  );
}
