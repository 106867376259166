import styles from "./InventoryList.module.css";
import "react-toastify/dist/ReactToastify.css";
import { Medication } from "../../../types/medication";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import MedicationPopup from "../MedicationPopup";
import SecondaryButton from "../../../components/Button2/SecondaryButton";

interface InventoryListItemProps {
  medication: Medication;
  index: number;
}

export default function InventoryListItem(props: InventoryListItemProps) {
  const m = props.medication;

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const showDetail = () => {
    setGlobalOverlay(
      <MedicationPopup
        medication={m}
        onCancel={hideGlobalOverlay}
      ></MedicationPopup>
    );
  };

  return (
    <div
      className={`${styles.inventoryListItem} ${
        props.index % 2 === 0 ? styles.shaded : ""
      }`}
    >
      <div className={styles.nameWrap}>
        <p className={styles.name} onClick={showDetail}>
          {m.displayName} <span className={styles.strength}> {m.strength}</span>
        </p>
      </div>
      <p className={styles.stock}>
        {props.medication.availableStock} {props.medication.formulation}{" "}
        available
      </p>
      <div className={styles.buttonWrap}>
        <SecondaryButton onClick={showDetail}>Select</SecondaryButton>
      </div>
    </div>
  );
}
