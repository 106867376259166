import { useEffect, useState } from "react";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import styles from "./styles.module.css";
import { DispenseDetail } from "../../types/dispense";
import { ApiStatus } from "../../types/api_status";
import { getDispenseHistory } from "./api";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Search from "../../components/Search/Search";
import { getFedexTrackingUrl, getPrettyDate } from "../../utils";

enum DispenseFilter {
  ALL,
  PAST,
  UPCOMING,
}

const _filterDispenses = (
  filter: DispenseFilter,
  queryFilter: string,
  dispenses: DispenseDetail[]
) => {
  if (filter === DispenseFilter.PAST) {
    dispenses = dispenses.filter((d) => d.dispenseDate < new Date());
  } else if (filter === DispenseFilter.UPCOMING) {
    dispenses = dispenses.filter((d) => d.dispenseDate > new Date());
  }
  return dispenses.filter(
    (d) =>
      d.patientName.toUpperCase().includes(queryFilter.toUpperCase()) ||
      d.medicationName.toUpperCase().includes(queryFilter.toUpperCase())
  );
};

export default function DispenseHistory() {
  const [dispensesFilter, setDispensesFilter] = useState<DispenseFilter>(
    DispenseFilter.ALL
  );

  const [dispensesFetchState, setDispensesFetchState] = useState<{
    status: ApiStatus;
    dispenses: DispenseDetail[] | null;
  }>({
    status: ApiStatus.LOADING,
    dispenses: null,
  });

  const [dispensesQueryFilter, setDispensesQueryFilter] = useState("");

  const _getDispenses = () => {
    getDispenseHistory()
      .then((dispenses) => {
        setDispensesFetchState({
          dispenses: dispenses,
          status: ApiStatus.SUCCESS,
        });
      })
      .catch(() =>
        setDispensesFetchState((prev) => ({
          ...prev,
          status: ApiStatus.FAILURE,
        }))
      );
  };

  useEffect(() => {
    _getDispenses();
  }, []);

  return (
    <DashboardPageBody
      title={"Dispense history"}
      headerSuffix={
        <div className={styles.suffix}>
          <Search
            placeholder="Search dispenses"
            onChange={(val) => setDispensesQueryFilter(val)}
          ></Search>
        </div>
      }
    >
      <div className={styles.filters}>
        <button
          onClick={() => setDispensesFilter(DispenseFilter.ALL)}
          className={`${styles.filter} ${
            dispensesFilter === DispenseFilter.ALL
              ? styles.filterActive
              : styles.filterInactive
          }`}
        >
          All dispenses
        </button>
        <button
          onClick={() => setDispensesFilter(DispenseFilter.PAST)}
          className={`${styles.filter} ${
            dispensesFilter === DispenseFilter.PAST
              ? styles.filterActive
              : styles.filterInactive
          }`}
        >
          Past dispenses
        </button>
        <button
          onClick={() => setDispensesFilter(DispenseFilter.UPCOMING)}
          className={`${styles.filter} ${
            dispensesFilter === DispenseFilter.UPCOMING
              ? styles.filterActive
              : styles.filterInactive
          }`}
        >
          Future dispenses
        </button>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Patient</th>
            <th>Date</th>
            <th>Prescription</th>
            <th>Consent form</th>
            <th>Status</th>
            <th>Tracking</th>
          </tr>
        </thead>
        <tbody>
          {dispensesFetchState.dispenses ? (
            dispensesFetchState.dispenses.length > 0 ? (
              _filterDispenses(
                dispensesFilter,
                dispensesQueryFilter,
                dispensesFetchState.dispenses
              ).length === 0 ? (
                <tr>
                  <td colSpan={7} className={styles.emptyState}>
                    No results
                  </td>
                </tr>
              ) : (
                _filterDispenses(
                  dispensesFilter,
                  dispensesQueryFilter,
                  dispensesFetchState.dispenses
                ).map((d, i) => (
                  <tr>
                    <td>
                      {/* {d.patientName} */}
                      <Link
                        to={`/patients/${d.patientId}?back=/dispenses`}
                        className={styles.tableLink}
                      >
                        {d.patientName}
                      </Link>
                    </td>
                    <td>{getPrettyDate(d.dispenseDate)}</td>
                    <td>
                      {d.medicationName} {d.medicationStrength},{" "}
                      {d.medicationQuantity} {d.medicationFormulation}
                      {/* <Link
                        to={`/patients/${d.patientId}/prescriptions/${d.prescriptionId}`}
                        className={styles.patientLink}
                      >
                        {d.medicationName} {d.medicationStrength}
                      </Link> */}
                    </td>
                    <td>
                      {d.signedConsentForm ? (
                        <a
                          href={d.signedConsentForm}
                          className={styles.tableLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View
                        </a>
                      ) : (
                        <p>Unsigned</p>
                      )}
                    </td>
                    <td>{d.shippingStatus}</td>
                    <td>
                      {d.trackingNumber === "" ? (
                        "N/A"
                      ) : (
                        <a
                          href={getFedexTrackingUrl(d.trackingNumber)}
                          className={styles.tableLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {d.trackingNumber}
                        </a>
                      )}
                    </td>
                  </tr>
                ))
              )
            ) : (
              <tr>
                <td colSpan={7} className={styles.emptyState}>
                  No dispenses yet
                </td>
              </tr>
            )
          ) : dispensesFetchState.status === ApiStatus.LOADING ? (
            <tr>
              <td colSpan={7} className={styles.emptyState}>
                <BeatLoader
                  color="var(--color-primary)"
                  style={{ display: "block" }}
                ></BeatLoader>
              </td>
            </tr>
          ) : (
            <td colSpan={7} className={styles.emptyState}>
              Error loading dispenses
            </td>
          )}
        </tbody>
      </table>
    </DashboardPageBody>
  );
}
