import { useAuth0 } from "@auth0/auth0-react";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { getAuthUser, getCsrf } from "../api/authentication_network_api";
import AppLoading from "../pages/AppLoading/AppLoading";
import { ApiStatus } from "../types/api_status";
import { User } from "../types/user";
import { addAxiosAuthInterceptor } from "../api/http_client";

interface AuthProviderState {
  user: User | null;
  userFetchStatus: ApiStatus;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextValue {
  user: User | null;
  updateUser: (user: User) => void;
}

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

const AuthUserProvider = (props: AuthProviderProps) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    addAxiosAuthInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  const [state, setState] = useState<AuthProviderState>({
    user: null,
    userFetchStatus: ApiStatus.LOADING,
  });

  useEffect(() => {
    getCsrf().catch(() => console.log("CSRF fetch failed"));
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getAuthUser()
        .then((user) =>
          setState((prev) => ({
            ...prev,
            user,
            userFetchStatus: ApiStatus.SUCCESS,
          }))
        )
        .catch((prev) =>
          setState(() => ({ ...prev, userFetchStatus: ApiStatus.FAILURE }))
        );
    }
  }, [isAuthenticated]);

  const handleUpdate = (user: User) => {
    setState((prev) => ({ ...prev, user }));
  };

  const value = {
    user: state.user,
    updateUser: handleUpdate,
  };

  return (
    <AuthContext.Provider value={value}>
      {isLoading ? <AppLoading></AppLoading> : props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthUserProvider;
