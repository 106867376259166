import styles from "./OrderHistoryConsentLink.module.css";
import Card from "../../../components/Card/Card";
import Overlay from "../../../components/Overlay/Overlay";
import WarningIcon from "../../../assets/Warning.svg";
import Button from "../../../components/Button/Button";
import { CONSENT_ACCESS_CODE_URL } from "../../../constants";
import { User } from "../../../types/user";
import { useAuth } from "../../../providers/AuthUserProvider";
import { assert } from "../../../utils";
import Copy from "../../../assets/Copy.svg";

const SUBJECT_TEMPLATE = "ACTION REQUIRED: Fulfilling Your Prescription";

function getEmailTemplate(patientName: string, user: User, url: string) {
  return `Dear ${patientName},

Thank you for being a valued ${user.organization} customer. Our partner pharmacy, MediCircle, will be fulfilling your prescription. 
    
Before MediCircle delivers your medication, you will need to complete a quick form. The form should take no longer than five minutes: 

${url}
    
If you have any questions or need assistance at any point during this process, please do not hesitate to reach out.
    
Thank you, and have a great day. 
    
Sincerely,
${user.fullName}
${user.organization}`;
}

export default function OrderHistoryConsentLink(props: {
  token: string;
  patientName: string;
  onDone: () => void;
}) {
  const url = `${CONSENT_ACCESS_CODE_URL}?access_code=${props.token}`;

  const { user } = useAuth();
  assert(user);

  const copyTemplate = () => {
    navigator.clipboard.writeText(
      getEmailTemplate(props.patientName, user, url)
    );
  };

  const copySubject = () => {
    navigator.clipboard.writeText(SUBJECT_TEMPLATE);
  };

  return (
    <Overlay>
      <Card className={styles.orderHistoryConsentLink}>
        <h2 className={styles.title}>Consent Form Email</h2>
        <p className={styles.subtitle}>
          Copy and send the following email to {props.patientName} so that they
          can fill out the necessary consent forms for this order.
        </p>
        <div className={styles.warning}>
          <img className={styles.warningIcon} src={WarningIcon} alt={""}></img>
          <div>
            <p className={styles.warningHeader}>
              This information is confidential
            </p>
            <p>
              Do not share this with anybody other than {props.patientName}.
            </p>
          </div>
        </div>
        <div className={styles.templates}>
          <div className={styles.templateBox}>
            <p className={styles.subject}>{SUBJECT_TEMPLATE}</p>
            <button
              className={`${styles.copyBox} ${styles.copySubject}`}
              onClick={copySubject}
            >
              <img src={Copy} alt="Copy"></img>
            </button>
            <p>{getEmailTemplate(props.patientName, user, url)}</p>
            <button
              className={`${styles.copyBox} ${styles.copyContent}`}
              onClick={copyTemplate}
            >
              <img src={Copy} alt="Copy"></img>
            </button>
          </div>
        </div>

        <Button
          pill={false}
          width="100%"
          boxShadow="none"
          padding="16px"
          onClick={props.onDone}
        >
          Done
        </Button>
      </Card>
    </Overlay>
  );
}
