import axios from "axios";
import { BASE_API_URL } from "../constants";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const BASE_CONFIG = {
  baseURL: BASE_API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
};

const instance = axios.create(BASE_CONFIG);

export const addAxiosAuthInterceptor = (
  getAccessTokenSilently: () => Promise<string>
) => {
  instance.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export default instance;
