export interface MedicationJson {
  id: number;
  brand_name: string;
  generic_name: string;
  display_name: string;
  strength: string;
  available_quantity: number;
  avg_qty_per_script: number;
  pricing_tier: PricingTierJson;
  formulation_display: string;
}

export interface PricingTierJson {
  name: string;
  admin_fee: number;
  sourcing_fee: number;
  handling_fee: number;
  total_price: number;
}

export class Medication {
  constructor(
    public pk: number,
    public brandName: string,
    public genericName: string,
    public displayName: string,
    public strength: string,
    public availableStock: number,
    public avgQtyPerScript: number,
    public formulation: string,
    public pricingTier: PricingTier
  ) {}

  static fromJson(data: MedicationJson): Medication {
    return new Medication(
      data.id,
      data.brand_name,
      data.generic_name,
      data.display_name,
      data.strength,
      data.available_quantity,
      data.avg_qty_per_script,
      data.formulation_display,
      PricingTier.fromJson(data.pricing_tier)
    );
  }
}

export class PricingTier {
  constructor(
    public name: string,
    public adminFee: number,
    public sourcingFee: number,
    public handlingFee: number,
    public totalPrice: number
  ) {}

  static fromJson(data: PricingTierJson): PricingTier {
    return new PricingTier(
      data.name,
      data.admin_fee,
      data.sourcing_fee,
      data.handling_fee,
      data.total_price
    );
  }
}
