import styles from "./styles.module.css";

interface SecondaryButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

export default function SecondaryButton(props: SecondaryButtonProps) {
  return (
    <button
      className={`${styles.button} ${styles.secondary}`}
      onClick={props.onClick}
    >
      <span className={styles.content}>{props.children}</span>
    </button>
  );
}
