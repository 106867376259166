import { BeatLoader } from "react-spinners";
import { Prescription2 } from "../../../../types/prescription";
import styles from "./PrescriptionDetailBox.module.css";
import { getPrettyDate } from "../../../../utils";
import Rx from "../../../../assets/Rx.svg";
import { Link, useLocation } from "react-router-dom";
import { ApiStatus } from "../../../../types/api_status";
import { Patient } from "../../../Patients2/types";
import { useGlobalOverlay } from "../../../../providers/GlobalOverlayProvider";
import InfoDialog from "../../../../components/InfoDialog/InfoDialog";
import Info from "../../../../assets/Info.svg";

interface PrescriptionDetailBoxProps {
  prescription: Prescription2 | null;
  patient: Patient | null;
  status?: ApiStatus;
}

export default function PrescriptionDetailBox(
  props: PrescriptionDetailBoxProps
) {
  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const location = useLocation();
  return (
    <div className={styles.box}>
      {props.prescription && props.patient ? (
        <div>
          <div className={styles.detailHeader}>
            <img alt="Rx" src={Rx} className={styles.rxIcon}></img>
            <h3 className={styles.detailGridTitle}>Prescription Details</h3>
          </div>
          <div className={styles.detailGrid}>
            <div className={styles.detailItem}>
              <p className={styles.detailItemTitle}>Patient</p>
              <Link
                to={`/patients/${props.prescription.patientId}?back=${location.pathname}`}
                className={`${styles.detailItemText} ${styles.detailItemTextLink}`}
              >
                {props.patient.fullName}
              </Link>
            </div>
            <div className={styles.detailItem}>
              <p className={styles.detailItemTitle}>Medication</p>
              <p className={styles.detailItemText}>
                {props.prescription.medicationName}{" "}
                {props.prescription.medicationStrength}
              </p>
            </div>
            <div className={styles.detailItem}>
              <p className={styles.detailItemTitle}>Quantity</p>
              <p>
                {props.prescription.quantity}{" "}
                {props.prescription.medicationFormulation}
              </p>
            </div>

            <div className={styles.detailItem}>
              <p className={styles.detailItemTitle}>Created on</p>
              <p>{getPrettyDate(props.prescription.createdAt)}</p>
            </div>

            <div className={styles.detailItem}>
              <p className={styles.detailItemTitle}>Received</p>
              <p>
                {props.prescription.received ? (
                  "Yes"
                ) : (
                  <div className={styles.prescriptionReceivedBox}>
                    No
                    <button
                      className={styles.iconButton}
                      onClick={() =>
                        setGlobalOverlay(
                          <InfoDialog
                            title="We need this prescription"
                            subtitle="You can send it to us over fax at (832) 202-1304, call us at
              (832) 380-4400, or send it to MediCircle on Surescripts."
                            onClose={hideGlobalOverlay}
                          />
                        )
                      }
                    >
                      <img
                        src={Info}
                        title="Transfer prescription"
                        alt="Transfer prescription"
                      ></img>
                    </button>
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>
      ) : !props.prescription && props.status === ApiStatus.FAILURE ? (
        <p className={styles.errorMessage}>
          Error getting prescription details
        </p>
      ) : (
        <div className={styles.loader}>
          <BeatLoader color={"var(--color-primary)"} size={15}></BeatLoader>
        </div>
      )}
    </div>
  );
}
