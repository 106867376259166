import {
  PrescriptionListItem,
  PrescriptionListItemJson,
} from "../../types/prescription";
import { PatientListItem, PatientListItemJson } from "../Patients2/types";

interface TaskResultJson {
  untransferred_prescriptions: PrescriptionListItemJson[];
  unsigned_consent_forms: PatientListItemJson[];
}

export class TaskResult {
  constructor(
    public untransferredPrescriptions: PrescriptionListItem[],
    public patientsWithUnsignedForms: PatientListItem[]
  ) {}

  static fromJson(data: TaskResultJson): TaskResult {
    return new TaskResult(
      data.untransferred_prescriptions.map(PrescriptionListItem.fromJson),
      data.unsigned_consent_forms.map(PatientListItem.fromJson)
    );
  }
}
