import styles from "./ConsentFormLinkPopup.module.css";

import WarningIcon from "../../assets/Warning.svg";

import Copy from "../../assets/Copy.svg";
import { useAuth } from "../../providers/AuthUserProvider";
import { User } from "../../types/user";
import { assert } from "../../utils";
import Button from "../Button/Button";
import Card from "../Card/Card";
import Overlay from "../Overlay/Overlay";
import { CONSENT_ACCESS_CODE_URL } from "../../constants";
import { toast } from "react-toastify";
import { generateConsentAccessToken } from "./api";
import { ApiStatus } from "../../types/api_status";
import { useState } from "react";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { ConsentFormAccessToken } from "./types";

const SUBJECT_TEMPLATE = "ACTION REQUIRED: Fulfilling Your Prescription";

function getEmailTemplate(patientName: string, user: User, url: string) {
  return `Dear ${patientName},

Thank you for being a valued ${user.organization} customer. Our partner pharmacy, MediCircle, will be fulfilling your prescription. 
    
Before MediCircle delivers your medication, you will need to complete a quick form. The form should take no longer than five minutes: 

${url}

Please note that this link expires in 48 hours.
    
If you have any questions or need assistance at any point during this process, please do not hesitate to reach out.
    
Thank you, and have a great day. 
    
Sincerely,
${user.fullName}
${user.organization}`;
}

interface TokenGenerationState {
  token: ConsentFormAccessToken | null;
  status: ApiStatus;
}

export default function ConsentFormLinkPopup(props: {
  onDone: () => void;
  patientId: string;
}) {
  const [tokenGenerationState, setTokenGenerationState] =
    useState<TokenGenerationState>({ token: null, status: ApiStatus.INITIAL });

  const { user } = useAuth();
  assert(user);

  const copyTemplate = () => {
    if (!tokenGenerationState.token) {
      return;
    }
    navigator.clipboard.writeText(
      getEmailTemplate(
        tokenGenerationState.token.patientName,
        user,
        `${CONSENT_ACCESS_CODE_URL}?access_code=${tokenGenerationState.token.tokenValue}`
      )
    );
    toast.success("Text copied", { autoClose: 1500 });
  };

  const copySubject = () => {
    navigator.clipboard.writeText(SUBJECT_TEMPLATE);
    toast.success("Text copied", { autoClose: 1500 });
  };

  const _generateToken = () => {
    return generateConsentAccessToken(props.patientId);
  };

  return !tokenGenerationState.token ? (
    <ConfirmationDialog
      title="Confirm"
      subtitle="You are about to generate a consent form URL which will be valid for 48 hours. Proceed?"
      onCancel={props.onDone}
      loading={tokenGenerationState.status === ApiStatus.LOADING}
      onConfirm={() => {
        setTokenGenerationState((prev) => ({
          ...prev,
          status: ApiStatus.LOADING,
        }));
        _generateToken()
          .then((token) =>
            setTokenGenerationState(() => ({
              status: ApiStatus.SUCCESS,
              token: token,
            }))
          )
          .catch((e) => {
            alert("Error generating access token");
          });
      }}
    ></ConfirmationDialog>
  ) : (
    <Overlay>
      <Card className={styles.orderHistoryConsentLink}>
        <h2 className={styles.title}>Recipient form email</h2>
        <p className={styles.subtitle}>
          Copy and send the following email to{" "}
          {tokenGenerationState.token.patientName} so that they can sign
          outstanding recipient forms.
        </p>
        <div className={styles.warning}>
          <img className={styles.warningIcon} src={WarningIcon} alt={""}></img>
          <div>
            <p className={styles.warningHeader}>
              This information is confidential
            </p>
            <p>
              Do not share this with anybody other than{" "}
              {tokenGenerationState.token.patientName}.
            </p>
          </div>
        </div>
        <div className={styles.templates}>
          <div className={styles.templateBox}>
            <p className={styles.subject}>{SUBJECT_TEMPLATE}</p>
            <button
              className={`${styles.copyBox} ${styles.copySubject}`}
              onClick={copySubject}
            >
              <img src={Copy} alt="Copy"></img>
            </button>
            <p>
              {getEmailTemplate(
                tokenGenerationState.token.patientName,
                user,
                `${CONSENT_ACCESS_CODE_URL}?access_code=${tokenGenerationState.token.tokenValue}`
              )}
            </p>
            <button
              className={`${styles.copyBox} ${styles.copyContent}`}
              onClick={copyTemplate}
            >
              <img src={Copy} alt="Copy"></img>
            </button>
          </div>
        </div>

        <Button
          pill={false}
          width="100%"
          boxShadow="none"
          padding="12px"
          onClick={props.onDone}
        >
          Done
        </Button>
      </Card>
    </Overlay>
  );
}
