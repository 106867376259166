import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import FaqItem from "./FaqItem";
import styles from "./Faq.module.css";

export default function Faq() {
  return (
    <DashboardPageBody title={"FAQ"}>
      <p className={styles.subtitle}>
        Please refer to the information below for answers to frequently asked
        questions. If you have a question which is not answered here, please{" "}
        <a href="support">contact support.</a>
      </p>
      <FaqItem title="How much does MediCircle cost?" initiallyExpanded={true}>
        <>
          <p>
            Patients never pay anything to use MediCircle's services. MediCircle
            is completely free for patients.
          </p>
          <p>
            MediCircle does not charge for the cost of a medication. We do not
            resell medication, nor do we submit claims for reimbursement.
            Instead, MediCircle charges service fees to reflect the added value
            we provide to patients, payers, providers, and health systems.
          </p>
          <p>
            Our service fees cover a range of services, including but not
            limited to:
          </p>
          <ul style={{ marginTop: 0 }}>
            <li>
              <b>Recertification Processes</b>: Ensuring medications meet
              rigorous safety and compliance standards.
            </li>
            <li>
              <b>Procurement Efforts</b>: Partnering with health systems,
              hospitals, and other organizations to source necessary medications
              effectively.
            </li>
            <li>
              <b>Marketing and Expertise</b>: Using advanced strategies to
              identify and procure targeted medications.
            </li>
            <li>
              <b>Exceptional Experiences</b>: Providing seamless and
              compassionate customer support for patients, donors, and the donor
              community.
            </li>
            <li>
              <b>Legal Compliance</b>: Investing in legal work to ensure our
              operations meet all regulatory requirements.
            </li>
            <li>
              <b>Sourcing Fees</b>: Covering the costs associated with
              identifying and acquiring medications.
            </li>
            <li>
              <b>Proprietary Technology and Data</b>: The utilization of
              MediCircle proprietary or patented technology and date to most
              efficiently match and source medications.
            </li>
          </ul>
        </>
      </FaqItem>
      <FaqItem
        title="What are MediCircle's pricing tiers?"
        initiallyExpanded={true}
      >
        <>
          <p>
            The tier a medication falls into is based on the level of effort,
            investment, and expertise required to source it. Factors like
            additional work, capital, and specialized procurement strategies
            influence the tier assignment.
          </p>
          <p>Medication falls into one of the following tiers:</p>
          <ul style={{ marginTop: 0 }}>
            <li>
              <b>Bronze Tier</b>: $100 per month's worth of medication.
            </li>
            <li>
              <b>Silver Tier</b>: $1,000 per month's worth of medication.
            </li>
            <li>
              <b>Gold Tier</b>: $3,000 per month's worth of medication.
            </li>
            <li>
              <b>Platinum Tier</b>: $6,000 per month's worth of medication.
            </li>
          </ul>
          <p>
            The quantity in one month’s worth of medication is based on FDA
            prescribing guidelines.
          </p>
        </>
      </FaqItem>
    </DashboardPageBody>
  );
}
